import alfabank from "./Clients/images/alfabank.small.png"
import skoda from "./Clients/images/skoda_logo.33small.png"
import playtika from "./Clients/images/playtika.15033.svg"
import gazprom from "./Clients/images/gazprom-logo-small.png"
import deputy from "./Clients/images/image_86.png"
import artistek from "./Clients/images/artistek_system.png"
import mobile from "./Clients/images/image_85.png"
import logoInnowise from "./Clients/images/logoInnowise.png"
import logoSofteco from "./Clients/images/logoSofteco.png"
import logoVizor from "./Clients/images/logoVizor.png"
import logoLeverX from "./Clients/images/logoLeverX.png"
import belarusneft from "./Clients/images/belarusneft.small.png"
import alivaria from "./Clients/images/alivaria.png"
import minskEnergo from "./Clients/images/minskEnergo.png"
import swagmasha from "./Clients/images/swagmasha.png"
import storymode from "./Clients/images/storymode.png"
import epc from "./Clients/images/epc.png"
import belgosproject from "./Clients/images/belgosproject.png"
import ssoft from "./Clients/images/ssoft.png"
import mns from "./Clients/images/mns.small.png"
import mf from "./Clients/images/mf.small.png"
import beltelecom from "./Clients/images/beltelecom.small.png"
import btrain from "./Clients/images/btrain.small.png"
import belweb from "./Clients/images/belweb.small.png"

export const clientTestimonials = [
  {
    logo: logoVizor,
    text: "Благодаря подпискам Allsports смогли оптимизировать затраты на спортивные компенсации, сэкономили время и, главное, получили хороший фидбэк от работников.",
    position: "HR",
    name: "Vizor Games",
  },
  {
    logo: alfabank,
    text:
      "Выражаем благодарность руководству и команде AllSports за профессионализм и лёгкость в работе. Мы высоко оцениваем уникальность и удобство использования их предложения, так как это заметно упрощает процесс компенсации за спортивные активности, равно как и стимулирует сотрудников больше заботиться о своём здоровье.\n" +
      "Рекомендуем AllSports как надёжных и ответственных партнёров.",
    position: "Председатель Проф.",
    name: "Альфа-Банк",
  },
  {
    logo: skoda,
    text:
      "Для нас очень важно предоставлять нашим сотрудникам привлекательную рабочую атмосферу, а предложение карты AllSports для всех наших работников создаёт отличную дополнительную ценность, которая позволяет повышать их мотивацию и эффективность.\n" +
      "AllSports предлагают доступ к широкому спектру спортивных услуг по всему городу. От йоги и пилатеса до плавания и скалолазания, что позволяет нашим сотрудникам подобрать занятие себе по душе.",
    position: "HR",
    name: "Шкода",
  },
  {
    logo: playtika,
    text: "В ходе сотрудничества с “AllSports” мы убедились, что выбор был удачным как с точки зрения современного решения для HR, так и относительно удобства использования, и теперь наши сотрудники с удовольствием посещают залы. ",
    position: "HR",
    name: "Playtika",
  },
  {
    logo: gazprom,
    text:
      "Выражаем огромную благодарность команде AllSports за уникальную возможность разнообразить спортивную жизнь наших сотрудников, которая также позволяет значительно упростить работу подразделения HR и бухгалтерии. \n" +
      "Работая с AllSports мы получили отличный профессиональный сервис и помощь во всех возникающих вопросах.",
    position: "Sport Coordinator",
    name: "Gazprom",
  },
  {
    logo: deputy,
    text: "Альторос Девелопмент выражает огромную благодарность всем членам команды “Allsports” за значительный вклад в спортивную жизнь наших сотрудников. Искренне благодарим за индивидуальный подход, оперативность в решении любых нестандартных вопросов и высокий уровень профессионализма.",
    position: "Deputy Director",
    name: "Altoros Development",
  },
  {
    logo: artistek,
    text: "Для нашей компании важно создавать приятную атмосферу и комфортные условия работы для наших сотрудников. И компания AllSports предложила нам отличные условия в качестве поддержки внутренней коммуникации. Теперь наши коллеги могут без особых усилий выбирать между широким спектром спортивных мероприятий по всему Минску - от плавательных бассейнов до занятий йогой и танцами.",
    position: "HR Director",
    name: "ARISTEK SYSTEMS",
  },
  {
    logo: mobile,
    text: "Мы получаем положительный опыт от сотрудничества с AllSports. Теперь мы можем предоставить для наших сотрудников больший выбор спортзалов по Минску в зависимости от увлечений. Сотрудники BP Mobile могут выбрать несколько спортзалов и заниматься разными видами спорта одновременно, что очень удобно.",
    position: "HR Director",
    name: "BP Mobile",
  },
  {
    logo: logoSofteco,
    text: 'Мы получили очень много положительных отзывов от коллег. Покупая один абонемент, получаешь сразу много интересных спортивных активностей. Можно и на скалодром сходить, и в теннис поиграть, и посетить тренажёрку. Ребята довольны. В SoftTeco программа действует в офисах в Минске, Гомеле и Витебске.',
    position: 'lead HR manager',
    name: 'Softeco'
  },
  {
    logo: logoLeverX,
    text: 'Наша компания всегда поощряла стремление сотрудников заниматься спортом, поэтому компенсация спортивных занятий уже давно входит в соцпакет LeverX Group... Так мы можем приобщать к спорту не только сотрудников минских офисов, но и часть команды, которая работает в регионах.',
    position: 'HR',
    name: 'LeverX'
  },
  {
    logo: logoInnowise,
    text: 'Для нас, как разработчиков, имеет важное значение современные решения и простота внедрения для создания комфортных условий нашим работникам. AllSports помогли нам в разы упростить работу с выбором спортзалов, потому что теперь наши сотрудники могут выбрать несколько видов спорта и платить одну и ту же цену.',
    position: 'HR',
    name: 'Innowise Group'
  },
  {
    logo: alivaria,
    text: 'Еду на маникюр, например, открываю приложение Аllsports, ищу зал рядом с собой - и  иду заниматься. Очень удобно.',
    position: 'сотрудник компании',
    name: 'Аливария'
  },
  {
    logo: ssoft,
    text: 'Это просто супер удобно! Ходишь куда хочешь, не покупая абонемент в какой-то один зал.',
    position: 'HR',
    name: 'SSoft'
  },
  {
    logo: belgosproject,
    text: 'На сегодняшний день Allsports это просто  очень классная штука.',
    position: 'глава первичной профсоюзной организации',
    name: 'ОАО “Институт Белгоспроект”'
  },
  {
    logo: epc,
    text: 'Allsports - это просто очень удобно.',
    position: 'работники',
    name: 'ООО “Европейская процессинговая компания”'
  },
  {
    logo: belarusneft,
    text: "Карты AllSports помогли нам пересмотреть подход к спортивной активности сотрудников. Сейчас мы видим, что посещение ими спортивных объектов значительно выросло",
    position: "Председатель профсоюзного комитета",
    name: "Белоруснефть",
  },
  {
    logo: storymode,
    text: "Не нужно платить за кучу абонементов, все в одной подписке:супер!",
    position: "HR",
    name: "Story Mode",
  },
  {
    logo: swagmasha,
    text: "Allsports - просто прекрысный сервис. В одной подписке собрано большое количество разннобразных локаций и активностей, вся информация о возможностях пользователя исчерпывающе представлена на сайте компании и в мобильном приложении Allsports.",
    position: "HR",
    name: "Swagmasha",
  },
  {
    logo: minskEnergo,
    text: "В сервисе Allsports нас в первую очередь привлек тот факт, что в его рамках наши работники могут заниматься в своих любимых спортивных локациях каждый день - это по сути безлимитный абонемент в сотни залов в Минске и по всей Беларуси. Это очень удобно.",
    position: "руководитель первичной профсоюзной организации",
    name: "РУП “Минскэнерго”",
  },
  {
    logo: mns,
    text: "От лица профсоюзного комитета выражаем благодарность компании Allsports за новый подход в продвижении спорта для сотрудников. Было получено множество положительных отзывов от коллег, которые оценили удобство сервиса и широкий выбор спортивных объектов",
    position: "Профсоюзный комитет",
    name: "Министерство по налогам и сборам",
  },
  {
    logo: mf,
    text: "Allsports – это действительно эффективный инструмент для улучшения социального пакета. Наши сотрудники стали гораздо чаще заниматься спортом, посещая различные объекты",
    position: "Профсоюзный комитет",
    name: "Министерство финансов",
  },
  {
    logo: beltelecom,
    text: "Выражаем благодарность команде AllSports за удобство и максимально гибкий подход к работе. Мы смогли наладить формат взаимодействия, который устраивает бухгалтерию, профсоюзный комитет, и помогает разнообразить спортивную жизнь сотрудников",
    position: "Профсоюзный комитет",
    name: "Белтелеком",
  },
  {
    logo: btrain,
    text: "Спортивные активности очень востребованы у наших сотрудников и мы всегда старались сделать спортивную жизнь в компании максимально разнообразной. С сервисом Allsports мы получили именно тот выбор объектов, который хотели. Благодарим за сотрудничество и надеемся на расширение географии услуг",
    position: "Профсоюзный комитет",
    name: "Управление белорусской железной дороги",
  },
  {
    logo: belweb,
    text: "Выражаем благодарность компании Allsports за эффективное  и взаимовыгодное сотрудничество. Среди всех активностей для сотрудников, спорт – один из главных приоритетов в нашей компании, потому что он позволяет улучшить взаимоотношения в коллективе и повысить производительность на рабочих местах",
    position: "Профсоюзный комитет",
    name: "БелВэб",
  },
]
